import React from 'react';
import _ from 'lodash';
import * as globalMessageActions from '../../../../../actions/globalMessageActions';
import * as clientActions from '../../../../../actions/client/clientActions';
import * as engagementActions from '../../../../../actions/client/engagementActions';
import clientService from '../../../../../utils/services/client';
import  BigNumber from 'bignumber.js';
import {connect} from 'react-redux';

@connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    clientObj: store.client.clientObj,
    pageCount: store.clientEngagement.pageCount,
    products: store.clientEngagement.engagementProducts
  };
})

class ProductTractionReport extends React.Component{
  constructor(){
    super();
    this.state = {
      currentSort: 'clicks',
      direction: 'desc',
      range: {
        from: '',
        to: ''
      },
      searchTerm: '',
      showFilters: false,
      pageCount: 30,
      currentPage: 1,
      nextPageAvailable: false
    };
  }

  componentWillMount () {
    this.props.dispatch(clientActions.getClient(this.props.loginCtx.clientId));
    this.initialLoad();
  }

  initialLoad(){
    var that = this;
    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=productTitle`;

    var query = {
      $offset: 0 ,
      $limit: that.state.pageCount
    };

    clientService.getSharedUrlsAccessProductsCount(that.props.loginCtx.clientId , {})
      .then(function(res){
        var totalCount = res.data[0].count;
        clientService.getSharedUrlsAccessProducts(that.props.loginCtx.clientId , query , extendedQuery)
          .then(function(res){
            that.setState({
              nextPageAvailable: (that.state.currentPage * that.state.pageCount) < totalCount,
              currentPage: 1
            });
            that.props.dispatch(engagementActions.updateEngagementProducts(res.data));
          })
          .catch(function(err){
            console.log('the error' , err);
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  loadMore (e){
    e.preventDefault();
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort ;
    var extendedQuery = `?$sort=${sortString}&$sort=productTitle`;

    var query = {
      $offset: that.state.currentPage * that.state.pageCount,
      $limit: that.state.pageCount
    };

    if(that.state.rangeQuery){
      Object.assign(query , that.state.rangeQuery);
    }

    that.setState({
      fetchingData: true
    });

    clientService.getSharedUrlsAccessProductsCount(that.props.loginCtx.clientId , that.state.rangeQuery ? that.state.rangeQuery : {})
      .then(function(res){
        var totalCount = res.data[0].count;
        clientService.getSharedUrlsAccessProducts(that.props.loginCtx.clientId , query ,extendedQuery)
          .then(function(res){
            var products = that.props.products.concat(res.data);
            that.props.dispatch(engagementActions.updateEngagementProducts(products));
            that.setState({
              nextPageAvailable: products.length  < totalCount,
              currentPage: that.state.currentPage + 1,
              fetchingData: false
            });
          })
          .catch(function(err){
            that.setState({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  sort(sortBy){
    var that = this;
    var direction = this.state.direction === 'desc' ? 'asc' : 'desc';


    this.setState({
      currentSort: sortBy,
      direction: direction,
      fetchingData: true
    });

    var sortString = that.state.direction === 'desc' ? sortBy : `-${sortBy}` ;
    var extendedQuery = `?$sort=${sortString}&$sort=productTitle`;

    var query = {
      $offset: 0,
      $limit: that.state.searchQuery ? false : that.state.currentPage * that.state.pageCount,
    };

    if(that.state.rangeQuery){
      query = Object.assign(query,that.state.rangeQuery);
    }

    if(that.state.searchQuery){
      query = Object.assign(query,that.state.searchQuery);
    }

    clientService.getSharedUrlsAccessProducts(that.props.loginCtx.clientId , query , extendedQuery)
      .then(function(res){
        that.setState({
          fetchingData: false
        });
        that.props.dispatch(engagementActions.updateEngagementProducts(res.data));
      })
      .catch(function(err){
        that.setState({
          fetchingData: false
        });
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }

  triggerFilterRange(e){

    if(e){
      e.preventDefault();
    }

    var that = this;

    this.rangeFilterContainer.classList.remove('error');
    this.from.classList.remove('error');
    this.to.classList.remove('error');

    if(!this.rangeFilter.value){
      this.rangeFilterContainer.classList.add('error');
      this.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Select \'Filter By\' Option',
          timeout: 10000
        }
      ));
      return;
    }

    //check that From value is not greater than To value
    if((this.from.value && this.to.value) && (parseInt(this.from.value) > parseInt(this.to.value))){
      this.from.classList.add('error');
      this.to.classList.add('error');
      this.props.dispatch(globalMessageActions.showMessage(
        {
          type: 'error',
          message: 'Start must not be greater than End',
          timeout: 10000
        }
      ));
      return;
    }

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=productTitle`;
    var rangeQuery = {};

    if(this.from.value){
      rangeQuery[`$${this.rangeFilter.value}_$gt`] = parseInt(this.from.value) - 1;
    }

    if(this.to.value){
      rangeQuery[`$${this.rangeFilter.value}_$lt`] = parseInt(this.to.value) + 1;
    }

    if(!that.from.value && !that.to.value){
      rangeQuery = null;
    }

    var query = {
      $offset: 0,
      $limit: that.state.searchQuery ? false : that.state.pageCount,
    };

    if(that.state.searchQuery){
      query = Object.assign(query,that.state.searchQuery);
    }

    if(rangeQuery){
      query = Object.assign(query, rangeQuery);
    }

    that.setState({
      rangeQuery: rangeQuery,
      fetchingData: true
    });

    clientService.getSharedUrlsAccessProductsCount(that.props.loginCtx.clientId , rangeQuery ? rangeQuery : {})
      .then(function(res){
        var totalCount = res.data[0].count;
        clientService.getSharedUrlsAccessProducts(that.props.loginCtx.clientId , query , extendedQuery)
          .then(function(res){
            that.props.dispatch(engagementActions.updateEngagementProducts(res.data));
            that.setState({
              offset: 0,
              range:{
                from: that.from.value,
                to: that.to.value
              },
              currentPage: 1,
              nextPageAvailable: that.state.searchQuery ? false : that.state.pageCount < totalCount,
              fetchingData: false
            });
          })
          .catch(function(err){
            that.setState({
              fetchingData: false
            });
            that.props.dispatch(globalMessageActions.showMessage(
              {
                type: 'error',
                message: err.response.data.message,
                timeout: 10000
              }
            ));
          });
      });
  }

  clearRange(rangeItem){

    this.from.classList.remove('error');
    this.to.classList.remove('error');

    this[rangeItem].value = '';
    this.setState({
      range:  {
        from: rangeItem === 'from' ? '' : this.state.range.from,
        to: rangeItem === 'to' ? '' : this.state.range.to
      }
    });

    this.triggerFilterRange();
  }

  searchProducts (e) {
    e.preventDefault();
    var that = this;

    var sortString = that.state.direction === 'desc' ? `-${that.state.currentSort}` : that.state.currentSort;
    var extendedQuery = `?$sort=${sortString}&$sort=productTitle`;

    var query = {
      $offset: 0,
    };

    var searchQuery = that.searchBox.value ? {$search: that.searchBox.value} : null;
    query = Object.assign(query ,searchQuery);
    that.setState({
      searchQuery: searchQuery,
      fetchingData: true,
      searchTerm: that.searchBox.value
    });


    if(!searchQuery){
      that.initialLoad();
      return;
    }

    clientService.getSharedUrlsAccessProducts(that.props.loginCtx.clientId , query , extendedQuery)
      .then(function(res){

        //clear range filter
        that.from.value = '';
        that.to.value = '';

        that.setState({
          nextPageAvailable: false,
          range: {
            from: '',
            to: ''
          },
          currentPage: 1,
          fetchingData: false
        });
        that.props.dispatch(engagementActions.updateEngagementProducts(res.data));
      })
      .catch(function(err){
        that.setState({
          fetchingData: false
        });
        that.props.dispatch(globalMessageActions.showMessage(
          {
            type: 'error',
            message: err.response.data.message,
            timeout: 10000
          }
        ));
      });
  }

  clearSearch(){

    this.searchBox.value = '';
    this.setState({
      searchTerm: '',
      searchQuery: null
    });
    this.initialLoad();
  }

  getSortDirectionImage(direction){
    return(
      direction == 'desc' ? <i className="fa fa-chevron-down"></i> : <i className="fa fa-chevron-up"></i>
    );
  }

  toggleFilters(){
    this.setState({
      showFilters: !this.state.showFilters
    });
  }

  render () {
    return (
      <div className='engagement-client'>
        {this.props.clientObj && <div className="grid-100">
          <div onClick={() => this.toggleFilters()} className="show-filters-tab hide-on-tablet hide-on-desktop">
            {
              this.state.showFilters ?
                <div>Hide Filters <i className="fa fa-minus"></i></div>
                :
                <div>Show Filters <i className="fa fa-plus"></i></div>
            }
          </div>
          <div className={('toolbar grid-100 grid-parent ') + (this.state.showFilters ? '' : 'close') }>
            <div className="report-search-form">
              <form onSubmit={(e) => this.searchProducts(e) }>
                <input ref={ (input) => this.searchBox = input } type="text" placeholder="Search"/>
                <button className="primary" type="submit">Search</button>
              </form>
              {
                this.state.searchTerm != '' ?
                  <div className="toolbar-tab">
                    {this.state.searchTerm}
                    <i onClick={() => this.clearSearch() } className="fa fa-times"></i>
                  </div>
                  :
                  false
              }
            </div>
            <div className="report-range-finder">
              <form action="">
                <div ref={ (selectContainer) => this.rangeFilterContainer = selectContainer } className="styled-select">
                  <select ref={ (select) => this.rangeFilter = select }>
                    <option value="">Filter By</option>
                    <option value="clicks">Clicks</option>
                    <option value="cpc">CPC</option>
                    <option value="earnings">Revenue</option>
                  </select>
                </div>
                <label className="report-range-label">Inbetween</label>
                <div className="reports-range-filter">
                  <input type="number" ref={ (input) => this.from = input } placeholder="Start" />
                </div>
                <div className="reports-range-filter">
                  <input type="number"  ref={ (input) => this.to = input } placeholder="End"/>
                </div>
                <button className="primary range-apply-button" onClick={(e) => this.triggerFilterRange(e)} type="submit">Apply</button>
                <div className="clearfix"></div>
                <div className="text-right">
                  {
                    this.state.range.from != '' ?
                      <span className="toolbar-tab">
                          Start: {this.state.range.from}
                        <i onClick={() => this.clearRange('from')} className="fa fa-times"></i>
                        </span>
                      :
                      false
                  }
                  {
                    this.state.range.to != '' ?
                      <span  className="toolbar-tab">
                          End: {this.state.range.to}
                        <i onClick={() => this.clearRange('to')} className="fa fa-times"></i>
                        </span>
                      :
                      false
                  }
                </div>
              </form>
            </div>
          </div>
          <div className="grid-100 trending-data-holder grid-parent">
            {
              this.props.products && this.props.products.length ?
                <div className="table-container">
                  <table id="retailer-product-traction-table" className="report-table responsive">
                    <thead>
                    <tr>
                      <th width="50%" className={this.state.currentSort === 'productTitle' ? 'current' : ''}><a onClick={() => this.sort('productTitle')}><i className="fa fa-link"></i> Item { this.state.currentSort === 'productTitle' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
                      <th width="12.5%" className={this.state.currentSort === 'shares' ? 'current' : ''}><a onClick={() => this.sort('shares')}><i className="fa fa-share-square-o"></i> Shares  { this.state.currentSort === 'shares' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
                      <th width="12.5%" className={this.state.currentSort === 'clicks' ? 'current' : ''}><a onClick={() => this.sort('clicks')}><i className="fa fa-hand-o-up"></i> Clicks { this.state.currentSort === 'clicks' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
                      <th width="12.5%" className={this.state.currentSort === 'cpc' ? 'current' : ''}><a title="Cost Per Click" onClick={() => this.sort('cpc')}><i className="fa fa-line-chart"></i> CPC { this.state.currentSort === 'cpc' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
                      <th width="12.5%" className={this.state.currentSort === 'earnings' ? 'current' : ''}><a onClick={() => this.sort('earnings')}><i className="fa fa-money"></i> Revenue { this.state.currentSort === 'earnings' ? this.getSortDirectionImage(this.state.direction) : false }</a></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      _.map(this.props.products , function (url , key) {
                        return(
                          <tr key={key}>
                            <td className="text-left">
                              <div className="table-image-holder" style={{backgroundImage : url.productImage ? `url(${url.productImage})`  : 'url(/images/table-holding-image.jpg)'}}>
                              </div>
                              <a title={url.productTitle} className="truncate" href={url.productUrl} target='_blank'>{url.productTitle}</a>
                            </td>
                            <td>{url.shares}</td>
                            <td>{url.clicks}</td>
                            <td>£{new BigNumber(url.cpc).toFixed(2)}</td>
                            <td>£{new BigNumber(url.earnings).toFixed(2) }</td>
                          </tr>
                        );
                      })
                    }
                    </tbody>
                  </table>
                  {
                    this.state.nextPageAvailable ?
                      <div className="text-center">
                        <button onClick={ (e) => this.loadMore(e) } className="primary">View More</button>
                      </div>
                      :
                      false
                  }
                </div>
                :
                <div>
                  {
                    this.props.products ?
                      <div className="no-data">
                        <p>There is currently no data</p>
                      </div>
                      :
                      <div className="grid-100 text-center">
                        <div className="loading-gif text-center">
                          <img src="/images/loading-gif.gif"/>
                        </div>
                      </div>
                  }
                </div>
            }
          </div>
        </div>}
      </div>
    );
  }
}

export default ProductTractionReport;