import React from 'react';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Panel } from 'primereact/components/panel/Panel';

import config from '../../../../../../config/config';
import { getData } from './data';

class CodeBlockPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      campaignVersionId: this.props.campaignVersionId,
      data: this.props.data,
    };
  }

    visualizeAction = (rowData) => (
      <span
        className="fa fa-eye"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          window.open(
            `${config.API.BASEURL}/codeBlock/previewHtml/${rowData.codeBlockId}`,
            'newwindow',
            'width=800,height=500'
          );
        }}
      />
    );

    editAction = (rowData) => (
      <span
        className="fa fa-cog"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          window.open(
            `${config.URL.BASEFRONT}/admin/codeBlock/${rowData.codeBlockId}`,
            '_blank'
          );
        }}
      />
    );

    onLazyLoad = (event) => {
      if (event) {
        this.state.first = isNaN(event.first) ? 0 : event.first;
      }

      this.setState({ loading: true });
      getData(this.state.campaignVersionId).then((response) => {
        this.setState({
          list: response,
          rewardPoolName: response.rewardPoolName,
          loading: false,
        });
      });
    };

    componentDidMount = () => this.onLazyLoad();

    render = () => (
      <Panel header={<span style={{ color: '#485465' }}>Code Blocks</span>}>
        <DataTable
          value={this.state.list}
          responsive={true}
          onLazyLoad
          sortMode="multiple"
          lazy={true}
          onLazyLoad={this.onLazyLoad}
          loading={this.state.loading}
          ref={(el) => (this.dt = el)}
          first={this.state.first}
        >
          <Column field="name" header="Name" />
          <Column field="type" header="Type" />
          <Column
            body={(e) => this.visualizeAction(e)}
            style={{ textAlign: 'center', width: '50px' }}
          />
          <Column
            body={(e) => this.editAction(e)}
            style={{ textAlign: 'center', width: '50px' }}
          />
        </DataTable>
        <hr />
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <a
            href={`${config.URL.NEWFRONT}/asset/search?cpv_id=${this.props.campaignVersionId}`}
            target="_blank"
          >
                    Global lightbox
          </a>
          <a
            href={`/lightbox/test.html?env=${config.ENVIRONMENT}&testMode=true&clientId=${this.props.data.clientId}&campaignVersionId=${this.props.campaignVersionId}`}
            target="_blank"
          >
            <i
              class="fa fa-external-link-square"
              aria-hidden="true"
            ></i>
          </a>
        </div>
      </Panel>
    );
}

export default CodeBlockPanel;
