import React from 'react';
import { Link } from 'react-router';

class SliderMenu extends React.Component{

  goTo(route){
    this.props.closeHeaderMenu();
    this.props.goTo(route);
  }

  render(){
    const routes = this.props.menuItems;

    const listItems = routes.map((route) => {

      if(route.route.indexOf('http') > -1){
        return <li key={route.name} className="menuitem">
          <a href={route.route} target='_blank'>
            <div className={route.className}></div>
            <span>{route.name}</span>
          </a>
        </li>;
      }

      if (!route.beta || (Boolean(route.beta) === true && this.props.isImpersonator)) {
        return <li key={route.name} className="menuitem">
          <Link activeClassName={'active'} to={{ pathname: route.route }} ta>
            <div className={route.className}></div>
            <span>{route.name}</span>
          </Link>
        </li>;
      }
    }
    );
    return(
      <div id="sidebarMainMenu">
        <ul>
          {listItems}
          {/* <li>
            <a onClick={this.props.logout} >
              <i className='fa fa-sign-out'> </i>
              <br/>
              Logout
            </a>
          </li> */}
        </ul>
      </div>
    );
  }
}

export default SliderMenu;