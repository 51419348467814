import React from 'react';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {Menu} from 'primereact/components/menu/Menu';
import {Button} from 'primereact/components/button/Button';

import config from  '../../../../../../config/config';
import campaignVersionService from '../../../../../../utils/services/campaignVersion';
import RewardPanel from '../reward/panel';
import EmailPanel from '../email/panel';
import CodeBlockPanel from '../codeBlock/panel';
import Label from  '../../../../../shared/label/label';
import {Sidebar} from 'primereact/components/sidebar/Sidebar';
import {ScrollPanel} from 'primereact/components/scrollpanel/ScrollPanel';
import GlobalVar from '../../../../../shared/globalVar/GlobalVar';

import './style.css';
import { CopyCampaignVersion } from '../../../../../shared/copyCampaignVersion/CopyCampaignVersion';

class CampaignVersionDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      searchField: '',
      campaignId: this.props.campaignId,
      runningCampaign: this.props.running,
      ignoreArchived: this.props.ignoreArchived,
      selectedCampaignId: '',
      settingsPanelVisible: false,
      selectedCampaignVersionId: '',
      copyCampaignVersionModalOpened: false
    };

    this.withToolTip = this.withToolTip.bind(this);
  }

  editAction =  rowData => <span className="fa fa-cog" style={{cursor: 'pointer'}} onClick={() => {
    window.open(`${config.URL.BASEFRONT}/admin/campaignVersion/${rowData._id}`, '_blank');
  }}/>

  optionsAction = (rowData) => {

    let items = [
      {
        label: 'Replicate Campaign Version',
        items: [
          {
            label: 'Boost it! (Beta)', 
            command:(e) => 
            { 
              this.setState(
                {
                  selectedCampaignVersionId: rowData._id, 
                  copyCampaignVersionModalOpened: true
                });
            }
          }]
      }
    ];

    if(!this.menu){
      this.menu = {};
    }

    return (
      <div>
        <Menu model={items} popup={true} ref={el => this.menu[rowData._id]=el} className="campaign-version-dropdown-menu"/>
        <div 
          className="fa fa-ellipsis-v" 
          onClick={(event)=> {
            this.menu[rowData._id].toggle(event);
            this.menu[rowData._id].container.style.position = 'sticky';
          }} 
          style={{width: '100%', cursor: 'pointer'}}></div>
      </div>
    );
  }
  
  openGlobalVarModal = rowData => <span className="fa fa-sliders" style={{cursor: 'pointer'}} onClick={e => this.showSettingsPanel(e, rowData)}/>

  rowExpansionTemplate = (rowData) => {

    let className = !rowData.active ? 
      'campaign-version-datatable-details-inactive':
      (this.state.runningCampaign ? 'campaign-version-datatable-details-running' : 'campaign-version-datatable-details');

    return (
      <div className={className}>
        <h5 style={{marginTop: '5px', marginBottom:'5px'}}>Campaign Version Details</h5>
        <div className="ui-g">
          <div className="ui-g-4">
            <RewardPanel campaignVersionId={rowData._id} rewardPoolId= {rowData.rewardPoolId} />
          </div>
          <div className="ui-g-4">
            <EmailPanel campaignVersionId={rowData._id} />
          </div>
          <div className="ui-g-4">
            <CodeBlockPanel data={rowData} campaignVersionId={rowData._id}/>
          </div>
        </div>   
      </div>); 
  }

  status = rowData => 
    <React.Fragment>
      {rowData.active && !rowData.running && <Label color="darkslategray" text="Active" />}
      {!rowData.active && !rowData.archived && <Label color="red" text="Inactive" />}
      {rowData.archived && <Label color="orange" text="Archived" />}
    </React.Fragment>

  getExtendedQuery = e => {
    var extendedQuery = '?';
    if(e.multiSortMeta != null && e.multiSortMeta.length > 0) {
      for(let sortMeta of e.multiSortMeta) {
        var sortField = `$sort=${sortMeta.order == 1 ? '' : '-' }${sortMeta.field}&`;
        extendedQuery = extendedQuery + sortField;
      }
    }
    return extendedQuery;
  }

  onLazyLoad = (e) => {
    const {campaignId, ignoreArchived} = this.state;

    this.state.first = isNaN(e.first) ? 0 : e.first;

    this.setState({ loading: true});

    const query = {campaignId_$: campaignId};
    if (ignoreArchived) query.archived_$ = false;

    campaignVersionService.getCampaignVersionList(query, this.getExtendedQuery(e))
      .then(response => this.setState({
        list: response.data.page,
        totalRecords: response.data.totalCount,
        loading: false
      }) );
  }

  withToolTip(rowData, column)
  {
    const value = rowData[column.field];
    return <span title={value}>{value}</span>;
  }

  componentDidMount = () => this.onLazyLoad({});

  renderSourceTags = (rowData) => {

    if(rowData && rowData.sourceTags && rowData.sourceTags.length > 0){

      return (
        <div>
          {
            rowData.sourceTags.map(i => <Label color="gray" text={i} />)
          }
        </div>
      );
    }
  }

  nameBody = (rowData) => {

    let flowTypeLabel = {
      sharer_triggered_reward: 'Sharer Triggered Reward',
      friend_triggered_reward: 'Friend Triggered Reward',
      both_triggered_reward: 'Both Triggered Reward',
      advertisement: 'Advertisement',
      direct_reward: 'Direct Reward',
      custom_flow: 'Custom Flow'
    };

    return (
      <div>
        <div>
          {rowData.flowType &&
          <Label color="gray" text={flowTypeLabel[rowData.flowType]} />
          }
        </div>
        <span> {rowData.name}</span>
      </div>
    );
  }

  showSettingsPanel(e, rowData){
    this.setState(
      {
        selectedCampaignClientName: rowData.clientName,
        selectedCampaignName: rowData.campaignName,
        selectedCampaignId: rowData._id, 
        settingsPanelVisible:true
      }
    );
  }

  onCloseSettingsModal = () =>{
    this.setState({settingsPanelVisible:false});
  }

  renderSettingsDialog = () =>{
    return( 
      <Sidebar visible={this.state.settingsPanelVisible} fullScreen={true} onHide={(e) => this.setState({settingsPanelVisible:false})} blockScroll={true}>
        <h3>
          <span style={{color:'#184a99'}}>Client:</span> {this.state.selectedCampaignClientName}
              
          <span style={{color:'#184a99'}}> Campaign:</span> {this.state.selectedCampaignName}
        </h3>
        <hr></hr>
        <ScrollPanel style={{ height: '100%'}}>
          <GlobalVar context='CAMPAIGN_VERSION.USER_JOURNEY' objectId={this.state.selectedCampaignId} modalMode={false} />
          <GlobalVar context='CAMPAIGN_VERSION.LIGHTBOX' objectId={this.state.selectedCampaignId} modalMode={false} />
          <GlobalVar context='CAMPAIGN_VERSION.LANDING_PAGE' objectId={this.state.selectedCampaignId} modalMode={false} />
          <GlobalVar context='CAMPAIGN_VERSION.SECURITY' objectId={this.state.selectedCampaignId} modalMode={false} />
          <GlobalVar context='CAMPAIGN_VERSION.REWARD' objectId={this.state.selectedCampaignId}  modalMode={false} />
          <GlobalVar context='CAMPAIGN_VERSION.POST_REWARD' objectId={this.state.selectedCampaignId} modalMode={false} />
          <GlobalVar context='CAMPAIGN_VERSION.CUSTOM_FIELD' objectId={this.state.selectedCampaignId}  modalMode={false} />
          
          <br/><br/><br/><br/><br/><br/>
        </ScrollPanel>
      </Sidebar>);
  }


  render = () => {
   
    let className = ((this.state.runningCampaign) ? ' campaign-version-datatable-running' : 'campaign-version-datatable');
    
    return (
      <div className={className}>
        <h5 style={{marginTop: '5px', marginBottom:'5px'}}>Campaign Version</h5>
        <DataTable
          value={this.state.list}
          responsive={true}
          lazy={true}
          sortMode="multiple"
          onLazyLoad={e => this.onLazyLoad(e)}
          loading={this.state.loading}
          ref={(el) => this.dt = el}
          onRowToggle={e => this.setState({ expandedRows: e.data })}
          rowExpansionTemplate={(e) =>this.rowExpansionTemplate(e)}
          expandedRows={this.state.expandedRows}
          first = {this.state.first}
        >
          <Column expander={true} style={{ width: '45px' }} />
          <Column body={this.status} header="Status" style={{ width: '200px' }}/>
          <Column field="_id" header="ID" body={this.withToolTip} style={{width:'220px', overflow: 'hidden', textOverflow: 'ellipsis'}}/>
          <Column field="name" body={e => this.nameBody(e)} header="Name" sortable={true}/>
          <Column field="alias" header="Alias" style={{width: '250px'}} sortable={true}/>
          <Column field="sourceTags" header="Source Tags" body={this.renderSourceTags} style={{width: '250px'}}/>
          <Column field="exposure" header="Exposure" sortable={true} style={{width:'120px'}}/>
          <Column field="linkExpiryDays" header="Public Link Expiry Days" sortable={true} style={{width:'120px'}}/>  
          <Column field="privateLinkExpiryDays" header="Private Link Expiry Days" sortable={true} style={{width:'120px'}}/>  
          <Column field="documentUrl" header="Document URL" sortable={true} style={{width:'90px'}} body={(data)=> data.documentUrl && <a style={{color: '#485465'}} target="_blank" download href={data.documentUrl}><span className="fa fa-file-pdf-o" ></span></a>}/>
          <Column header="GV" body={this.openGlobalVarModal} style={{textAlign:'center', width: '50px'}}/>
          <Column body={this.optionsAction} style={{textAlign:'center', width: '50px'}}/>
          <Column body={this.editAction} style={{textAlign:'center', width: '50px'}}/>
        </DataTable> 
        {this.renderSettingsDialog()}

        {/* COPY CAMPAIGN VERSION MODAL */}
        <CopyCampaignVersion options={
          { 
            mode: 'boost',
            campaignVersionId: this.state.selectedCampaignVersionId,
            copyCampaignVersionModalOpened: this.state.copyCampaignVersionModalOpened
          }
        } 
        onHide={() => {
          this.setState({copyCampaignVersionModalOpened: false});
        }} />
      </div>);
  }
}

export default CampaignVersionDataTable;
