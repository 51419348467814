import React from 'react';
import { Link } from 'react-router';

class Menu extends React.Component{
  render(){
    const routes = this.props.menuItems;
    const listItems = routes.map((route) =>
      <li key={route.name} ><Link activeClassName={'active'} to={{ pathName: route.route}}>{route.name}</Link></li>
    );
    return(
      <ul id="mainMenu">
        {listItems}
      </ul>
    );
  }
}

export default Menu;